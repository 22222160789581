<template>
  <e-sidebar
    :title="sidebarHelper.title"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    no-close-on-backdrop
    width="500px"
    @save="onConfirmSubmit"
    @hidden="onHiddenSidebar"
  >
    <template #content>
      <FormulateForm
        ref="pdvSidebarForm"
        name="pdvSidebarForm"
      >
        <div>
          <div class="d-flex justify-content-between">
            <p>Valor disponível para sangria</p>
            <p>{{ totalOutputCashAvailable | currency }}</p>
          </div>

          <template v-if="typeAction === payBoxActionsEnum.CASH_UP">
            <b-row>
              <b-col md="12">
                <FormulateInput
                  v-if="form.isRefund"
                  id="sidebar_bleeding-charge_back_value"
                  v-model="lastBleeding.value"
                  name="valueChargeBack"
                  :is-edit="false"
                  type="label"
                  filter="currency"
                  class="input-size"
                  :label="$t('Valor a estornar (última sangria)')"
                />
              </b-col>
              <b-col md="12">
                <FormulateInput
                  v-if="!form.isRefund"
                  id="valueHelper"
                  ref="inputValueHelper"
                  v-model="form.value"
                  :is-edit="false"
                  type="text-number"
                  currency="R$"
                  :precision="2"
                  class="required input-size"
                  :label="$t('Valor a retirar')"
                  validation="required|min:1"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col :md="hasRefund ? '5' : '12'">
                <FormulateInput
                  id="sidebar_bleeding-not_refund_label"
                  v-model="form.isRefund"
                  type="switch"
                  :label="$t('Estornar sangria?')"
                  @change="onCancelBleeding"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <FormulateInput
                  v-model="form.observation"
                  type="textarea"
                  class="required"
                  :label="$t('Observação')"
                  validation="required|min:10"
                />
              </b-col>
            </b-row>
          </template>
        </div>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { formulateHelper, payBoxTypes, paymentTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import delegablePermissions from '@/utils/delegable-permissions'
import ESidebar from '@/views/components/ESidebar.vue'

const getInitialForm = () => ({
  value: 0,
  isRefund: false,
  observation: '',
  paymentMethodId: null,
})

const getInitialLastBleeding = () => ({
  id: null,
  cashBookId: null,
  date: null,
  user: {
    name: '',
    document: null,
    email: '',
  },
  operationType: '',
  origin: '',
  value: 0,
  observation: '',
})

export default {
  components: { BRow, BCol, ESidebar },

  mixins: [formulateHelper, paymentTypes, payBoxTypes],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      typeAction: null,
      sidebarHelper: {
        title: '',
        timer: null,
      },
      form: getInitialForm(),
      hasRefund: false,
      lastBleeding: getInitialLastBleeding(),
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv', ['totalOutputCashAvailable', 'bleedings']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['thermalPrinterAgentConfigPayload']),
  },

  methods: {
    ...mapActions('pages/pdv/payBoxPrint', ['printBleeding']),
    ...mapActions('pages/pdv', ['bleedingAction', 'bleedingRefundAction', 'fetchBleedings']),

    async onShowSidebar(type) {
      try {
        this.fetching = true
        this.showSidebar = true
        await this.fetchBleedings()

        this.form.value = 0
        this.sidebarHelper = { title: this.payBoxActionsDesc[type] }
        this.typeAction = type

        if (this.bleedings.length > 0) {
          const lastBleeding = this.bleedings[this.bleedings.length - 1]
          this.hasRefund = lastBleeding.operationType === this.operationTypeEnum.DEBIT
          if (this.hasRefund) {
            this.lastBleeding = lastBleeding
          } else {
            this.lastBleeding = getInitialLastBleeding()
          }
        }

        try {
          window.electronAPI.openCashDrawer(this.thermalPrinterAgentConfigPayload)
        } catch (err) {
          console.error(err)
        }

        setTimeout(() => {
          this.focusInput('#valueHelper')
          setTimeout(() => {
            document.querySelector('#valueHelper').select()
          }, 550)
        }, 500)
      } catch (error) {
        this.showWarning({ message: error.message })
      } finally {
        this.fetching = false
      }
    },

    onHiddenSidebar() {
      this.form = getInitialForm()
      this.showSidebar = false
      this.delegateUserData = null
    },

    async onCancelBleeding(val) {
      try {
        this.form.isRefund = false
        if (val) {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_CANCEL_BLEEDING
          )
        }

        this.form.value = 0
        this.form.isRefund = val
      } catch (error) {
        this.form.isRefund = false
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },

    async onConfirmSubmit() {
      this.$refs.pdvSidebarForm.showErrors()
      if (this.$refs.pdvSidebarForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true

        if (!this.form.isRefund && this.totalOutputCashAvailable < this.form.value) {
          this.showWarning({
            message: this.$t('Valor disponível insuficiente para sangria.'),
          })
          return
        }

        const { CASH_UP } = this.payBoxActionsEnum

        if (this.typeAction === CASH_UP) {
          let bleedingData
          if (!this.form.isRefund) {
            const confirm = await this.confirm({
              icon: 'warning',
              title: this.$t('Confirma a sangria?'),
              text: this.$t(
                `Será realizado uma sangria no valor de ${this.$options.filters.currency(
                  this.form.value
                )}`
              ),
            })
            if (!confirm) return

            bleedingData = await this.bleedingAction(this.form)
          } else {
            const confirm = await this.confirm({
              icon: 'warning',
              title: this.$t('Confirma o estorno da sangria?'),
              text: this.$t(`Será estornado a última sangria realizada`),
            })
            if (!confirm) return

            bleedingData = await this.bleedingRefundAction({
              observation: this.form.observation,
              bleedingId: this.lastBleeding.id,
              delegateUserData: this.delegateUserData,
            })
          }

          await this.printBleeding({ bleedingData })
        }

        this.showSuccess({ message: '' })
        this.onHiddenSidebar()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-size {
  font-size: 1.4rem;
}
</style>
